import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import axios from 'axios';

import AdminSideImage from '../assets/admin.jpg'


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        General Solutions LK
      </Link>{' '}
      {new Date().getFullYear()}

    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',

  },
  image: {
    backgroundImage: `url(${AdminSideImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 'fontWeightBold'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontFamily: 'Poppins',
    fontWeight: 'fontWeightBold'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background:"#f54272",
    color:"#fff",
    fontFamily: 'Poppins',
    fontWeight: 'fontWeightBold'
  },
  text:{
    fontFamily: 'Poppins',
    fontWeight: 'fontWeightBold'
  },
}));

    
const Admin = ({authenticate}) => {

  const classes = useStyles();

  const [username,setUsernameDetails] =  useState("")
  const [password,setPasswordDetails] =  useState("")


  //Handle username form data
const handleUsernameInputChange =  (event) => {
    event.preventDefault()
    setUsernameDetails(
            event.target.value
    )
    }

//Handle password form data
const handlePasswordInputChange =  (event) => {
    event.preventDefault()
    setPasswordDetails(
            event.target.value
    )
    }


// Handle Login
const fetchLogin =  async () => {


  const requestOptions = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': ' application/json',
              },
      body: JSON.stringify({username, password})
  };

  const res = await fetch( process.env.REACT_APP_LOGIN_API_URL,requestOptions)

  const data = await res.json()

  if(data.token != null)
  {
    localStorage.setItem('Token',data.token)
    authenticate(true)

  }else{
    authenticate(false)
  }


}


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className={classes.text}>
            Sign in
          </Typography>
          <form className={classes.form} noValidate >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="username"
              autoComplete="email"
              autoFocus
              onChange={handleUsernameInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePasswordInputChange}
            />

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
              className={classes.text}
            />

            <Button
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={ (username != "" && password != "") ? fetchLogin : () => { alert("Username and Password can not be empty!")} }
            >
              Sign In
            </Button>

            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" className={classes.text}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" className={classes.text}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default Admin
